import React, { useMemo, useRef } from 'react';
import { FormProvider } from '@/hook-form';
import RHInput from '@/hook-form/RHInput';
import RHTextarea from '@/hook-form/RHTextarea';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { saveContact, ContactData } from '@/npc-api/contact';
import ReCAPTCHA from 'react-google-recaptcha';
import { useConfig } from '@/hooks/useConfig';
import { toast } from 'react-toastify';
import { GTM } from '@/utils/google-tag-manager';
import { GTMEventType } from '@/types/google-tag-manager.interface';

const GetInTouch = () => {
  const { captchaSiteKey } = useConfig();
  const recaptchaRef = useRef<ReCAPTCHA | null>(null);
  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
  const ContactSchema = Yup.object().shape({
    name: Yup.string().max(50).required('Name is required'),
    phone: Yup.string().matches(phoneRegExp, 'Phone number is not valid'),
    email: Yup.string().required('Email is required').email(),
    company: Yup.string(),
    message: Yup.string().required('Message is required'),
  });

  const defaultValues = useMemo(
    () => ({
      name: '',
      phone: '',
      email: '',
      company: '',
      message: '',
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const methods = useForm<ContactData>({
    resolver: yupResolver(ContactSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    reset,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = async (data: ContactData) => {
    if (!recaptchaRef.current?.getValue()) {
      toast.error(
        'Please check the Captcha field to validate you are not a robot'
      );
      return;
    }

    GTM.sendEvent({
      event: GTMEventType.RequestContact,
    });

    await saveContact(data).then((e) =>
      reset({ name: '', phone: '', email: '', company: '', message: '' })
    );
    recaptchaRef.current?.reset();
  };
  return (
    <section className="news-sec-div pb-5">
      <div className="container">
        <h1 className="text-center">
          <img src="images/heading-t.png" alt="picv" />{' '}
          <span className="d-block text-green">Get In Touch</span>{' '}
        </h1>
        <p className="text-center">
          {' '}
          Contact with us if you have any doubt. It will be a pleasure to care
          you.{' '}
        </p>
        <div className="news-sec-div-part mt-5">
          <div className="col-lg-12">
            <div className="row row-cols-1 row-cols-md-2">
              <div className="col">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1874.3726761922849!2d-80.35136088757572!3d25.87570218823876!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88d9bb6e616d5119%3A0x34cfd1a7019830b!2s11117%20W%20Okeechobee%20Rd%20%23130%2C%20Hialeah%2C%20FL%2033018%2C%20USA!5e0!3m2!1sen!2ses!4v1652882221404!5m2!1sen!2ses"
                  width="600"
                  height="450"
                  style={{ border: 0 }}
                  loading="lazy"
                  allowFullScreen={true}
                  referrerPolicy="no-referrer-when-downgrade"
                ></iframe>
              </div>
              <div className="col">
                <FormProvider
                  name="fwamn"
                  methods={methods}
                  onSubmit={handleSubmit(onSubmit)}
                >
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="form-group">
                        <RHInput
                          name="name"
                          type="text"
                          className="form-control"
                          placeholder="Full Name"
                          required={true}
                        />
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="form-group">
                        <RHInput
                          name="phone"
                          type="text"
                          className="form-control"
                          placeholder="Phone Number"
                          required={true}
                        />
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="form-group">
                        <RHInput
                          name="email"
                          type="text"
                          className="form-control"
                          placeholder="Email"
                          required={true}
                        />
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="form-group">
                        <RHInput
                          name="company"
                          type="text"
                          className="form-control"
                          placeholder="Company"
                        />
                      </div>
                    </div>

                    <div className="col-lg-12">
                      <div className="form-group">
                        <RHTextarea
                          className="form-control"
                          name="message"
                          placeholder="Your Question"
                        />
                      </div>
                    </div>

                    {captchaSiteKey && (
                      <div className="col-lg-12 mb-3">
                        <ReCAPTCHA
                          sitekey={captchaSiteKey}
                          ref={recaptchaRef}
                          size="normal"
                        />
                      </div>
                    )}

                    <div className="col-lg-12">
                      <div className="form-group">
                        <button
                          disabled={isSubmitting}
                          className="btn submit-bn-make"
                        >
                          {isSubmitting ? '...Loading' : 'Request info'}
                          <i className="fas fa-paper-plane m-2"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </FormProvider>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default GetInTouch;
